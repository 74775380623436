.evag {
  &-signatures {
    &-bar {
      border: solid 1px #000;
      border-radius: 8px;
      height: 16px;
      overflow: hidden;
      position: relative;
      width: 100%;
    }

    &-current {
      background: #000;
      bottom: 0;
      left: 0;
      position: absolute;
      top: 0;
    }

    &-text {
      font-size: 0.85em;
      margin-top: 1px;
    }
  }
}
