.evag {
  &-snackbar {
    background: #fa0000;
    border-radius: 5px;
    box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.4);
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    height: fit-content;
    left: 0;
    margin: 0 auto;
    max-width: 400px !important;
    padding: 15px;
    position: fixed;
    right: 0;
    transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
    z-index: 20;

    &-shown {
      top: 100px;
    }
  }
}
