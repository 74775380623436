[data-evag='multiforms'] {
  [data-evag='form'] {
    display: none;

    &:first-of-type {
      display: block;
    }
  }
}

.evag-captcha-text {
  margin-bottom: 20px;
}
